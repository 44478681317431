<template>
  <v-main>
    <v-container class="fill-height" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="4">
          <h2 class="text-center grey--text text--darken-2 pb-4">
            <v-icon color="primary" class="ml-2" large
              >mdi-monitor-dashboard</v-icon
            >
            Admin Panel
          </h2>
          <v-card>
            <!-- HEADER -->
            <v-toolbar color="secondary" dark flat>
              <v-toolbar-title>
                <v-icon class="ml-3">mdi-lock</v-icon>
                Login
              </v-toolbar-title>
            </v-toolbar>

            <!-- FORM -->
            <v-card-text class="pb-0">
              <v-form>
                <v-text-field
                  dense
                  outlined
                  type="text"
                  class="mt-5"
                  label="Email"
                  v-model="credentials.email"
                  prepend-icon="mdi-account-circle"
                  :error-messages="errors.email"
                />
                <v-text-field
                  dense
                  outlined
                  class="mb-1"
                  type="password"
                  label="Password"
                  prepend-icon="mdi-lock"
                  v-model="credentials.password"
                  :error-messages="errors.password"
                />
              </v-form>
            </v-card-text>

            <!-- ACTION -->
            <v-card-actions class="px-4 py-3">
              <v-spacer />
              <v-btn
                :loading="loading"
                color="secondary"
                @click="login(credentials)"
                >دخول</v-btn
              >
            </v-card-actions>
          </v-card>

          <!-- ALERTS -->
          <v-alert :value="showUnAuthAlert" type="error" class="mt-4">
            An error occurred when entering the email address or password.
          </v-alert>
          <v-alert :value="errors.message != null" type="error" class="mt-4">
            An error occurred when entering the email address or password.
          </v-alert>
          <v-alert :value="showForbiddenAlert" type="error" class="mt-4">
            There is no permession for entrance.
          </v-alert>
          <v-alert :value="showNetworkErrorAlert" type="error" class="mt-4">
            unknown error
          </v-alert>
           <v-alert :value="showPasswordErrorAlert" type="error" class="mt-4">
            this password is wrong
          </v-alert>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>


<script>
export default {
  name: "Login",

  data: function() {
    return {
      loading: false,
      credentials: {
        email: "",
        password: ""
      },
      errors: {
        message: null
      },
      showUnAuthAlert: false,
      showForbiddenAlert: false,
      showNetworkErrorAlert: false,
      showPasswordErrorAlert: false
    };
  },

  methods: {
    login(credentials) {
      this.resetErrors();
      this.loading = true;

      this.$auth
        .login({
          url: "/auth/login",
          data: credentials,
          staySignedIn: true,
          redirect: "/",
          fetchUser: false
        })
        .then(response => {
          this.$auth.user(response.data.data.user);
          this.$auth.token("token", response.data.data.access_token);
          this.$router.push({ path: "/" });
        })
        .catch(error => {
          // // console.log(error);
          if (error.response && error.response.status === 422) {
            this.errors.message = error.response.data.message;
          } else if (error.response && error.response.status === 401) {
            this.showUnAuthAlert = true;
          } else if (error.response && error.response.status === 403) {
            this.showForbiddenAlert = true;
          }else if(error.response && error.response.status === 400){
            this.showPasswordErrorAlert = true;
          }else {
            this.showNetworkErrorAlert = true;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    resetErrors() {
      this.errors = {};
      this.showUnAuthAlert = false;
      this.showForbiddenAlert = false;
      this.showNetworkErrorAlert = false;
      this.showPasswordErrorAlert = false;
    }
  }
};
</script>